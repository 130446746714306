import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const state = {
  collectionProducts: [],
  collectionInfo: {},
  collectionCart: [],
  productDetail: {},
  selectedCollectionProducts: {},
};

const mutations = {
  SET_COLLECTION_PRODUCTS (state, payload) {
    state.collectionProducts = payload;
  },
  SET_COLLECTION_INFO (state, payload) {
    state.collectionInfo = payload;
  },
  SET_COLLECTION_CART (state, payload) {
    state.collectionCart = payload;
  },
  SET_PRODUCT_DETAIL (state, payload) {
    state.productDetail = payload;
  },
  SET_COLLECTION_SELECTED_PRODUCTS (state, payload) {
    state.selectedCollectionProducts = payload;
  },
};

const getters = {
  collectionProducts: state => state.collectionProducts,
  collectionInfo: state => state.collectionInfo,
  collectionCart: state => state.collectionCart,
  productDetail: state => state.productDetail,
  selectedCollectionProducts: state => state.selectedCollectionProducts,
};

const actions = {
  async getCollections (_, payload) {
    const res = await http.get(apiEndpoints.getCollections, payload);
    const { status, data } = res;
    if (status) {
      return data.result;
    }
  },
  async getListProductByCollectionId (_, payload) {
    const res = await http.get(apiEndpoints.getProductByCollectionId.replace('{collection_id}', payload.collection_id), payload);
    const { status, data } = res;
    if (status) {
      return data.result;
    }
  },

  async getCollectionProducts ({ commit }, payload) {
    const res = await http.get(apiEndpoints.getCollectionProducts.replace('{collection_id}', payload.collection_id), payload);
    const { status, data } = res;
    if (status && data.result) {
      commit('SET_COLLECTION_PRODUCTS', data.result.products);
      commit('SET_COLLECTION_INFO', data.result.collection);
      return data.result;
    }
  },
  async getProduct ({ commit }, payload) {
    const res = await http.get(apiEndpoints.getProduct.replace('{product_id}', payload.product_id), payload);
    const { status, data } = res;
    if (status) {
      commit('SET_PRODUCT_DETAIL', data.result);
      return data.result;
    }
  },
  async getCollectionDetail ({ commit }, payload) {
    const res = await http.get(apiEndpoints.getCollectionInfo.replace('{id}', payload));
    const { status, data } = res;
    if (status && data.result) {
      commit('SET_COLLECTION_INFO', data.result.collection);
      return data.result;
    }
  },
  async checkAvailableVariants ({ commit }, payload) {
    const res = await http.post(apiEndpoints.checkAvailableVariants, payload);
    const { status, data } = res;
    if (status && data.result) {
      return data.result;
    }
  },
};
export default {
  actions,
  getters,
  state,
  mutations,
};
