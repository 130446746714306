export default {
  label: {
    search: '検索',
    paging: '/ページ',
    require: '必須',
    create: '新規作成',
    logout: 'ログアウト',
    yen: '円',
    required: '必須',
    yen_symbol: '￥',
  },
  button: {
    export_csv: 'CSV出力',
    create: '新規作成',
    keep: '保存',
    delete: '削除',
    cancel: 'キャンセル',
    add: '追加',
    complete: '完了',
    back: '戻る',
    next: '次へ',
    register: '登録',
    confirm: '確認',
    yes: 'はい',
    cart: 'カート',
    logout: 'ログアウト',
  },
  message: {
    success: '正常に保存しました。',
    error: 'エラーが発生しました。',
    no_data: 'データーがありません。',
    collection_exist_cart: 'のカートの商品が残っています。カートを空にするか、商品の注文を完了してください。',
  },
  placeholder: {
    postal_code: '1000000',
    phone_number: ' 090-1234-5678',
  },
  options: {
    all: 'すべて',
  },
  table: {
    nodata: '検索結果がありませんでした',
  },
  msg: {
    delete_item: 'こちらの項目を削除してもよろしいですか。',
  },
  header: {
    top: 'トップ',
    mypage: 'マイページ',
    faq: 'FAQ',
  },
  footer: {
    menu: {
      privacy_policy: 'プライバシーポリシー',
      terms_of_service: '利用規約',
      terms_of_use: '利用規約',
      return_policy: '返品ポリシー',
      specified_commercial: '特定商取引法に基づく表記',
      how_to_cancel_spam: '迷惑メール設定解除方法',
    },
    copy_right: '© Schoolpartner Inc. All rights reserved.  Powered by VIETIS',
  },
};
