export default {
  dialog: {
    message_confirm: 'こちらの商品を削除してもよろしいですか？',
  },
  button: {
    proceed_buy: '注文画面に進む',
    keep_shopping: 'お買い物を続ける',
  },
  message: {
    cart_empty: 'カートに商品は入っていません',
    notice: '※カートに商品を入れただけでは、商品を確保したことにはなりません。<br>注文手続き中に在庫がなくなる場合がございますので、ご注意ください。',
  },
  label: {
    title: 'ショッピングカート',
    check_all: '初台中学校',
    tax_include: '(税込)',
    quantity: '数量',
  },
};
